.tab-bar {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100vw;

  height: @tab-bar-height;
  background-color: @white;
  box-shadow: 0px -5px 7px #00000021;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding-top: 4px;
}
