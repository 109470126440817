.nav-bar {
  position: fixed;
  top: 0;
  z-index: 6;
  width: 100vw;
  background-color: @white;

  .title {
    font-size: 16px;
    margin-bottom: 0;
    color: @grey-4;
  }
}
