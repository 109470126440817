.full-loading {
  position: relative;
  .opacity-bg {
    opacity: 1;
    transition: opacity 0.4s;
    &.is-loading {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .loading {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    display: flex;
  }
}
