.search-bar {
  position: relative;

  .search-bar-content {
    position: relative;
    z-index: 10;
    background-color: @white;
    padding-top: 0.4em;
    // padding: 0.4em 0;
  }

  .dropdown {
    position: relative;

    .dropdown-content {
      position: absolute;
      top: 0;
      z-index: 8;
      background-color: @white;
      width: 100%;
      transition: transform 0.4s;
      transform: translate(0, -102%);
      padding-top: 1em;
    }
    .dropdown-mask {
      position: fixed;
      visibility: hidden;
      /* top: 0; */
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.55);
      opacity: 0;
      transition: visibility 0s, opacity 0.4s;
    }

    .range-date-picker-view {
      display: none;
      transform: translate(0, -100%);
      transition: display 0s, translate 0.1s;
      &.visible {
        display: block;
        transform: translate(0, 0);
      }
    }
  }
  &.visible {
    .dropdown {
      .dropdown-content {
        transform: translate(0, 0);
      }
      .dropdown-mask {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
