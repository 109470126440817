* {
  box-sizing: border-box;
}
html,
body,
#root,
.global-layout {
  height: 100%;
  overflow: hidden;
}

.w-100 {
  width: 100%;
}
.p-0 {
  padding: 0;
}
.mt-auto {
  margin-top: auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-1 {
  margin-top: 1em;
}

.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mb-4 {
  margin-bottom: 5em;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-nowrap {
  white-space: nowrap;
}

.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}

.text-black {
  color: @black;
}
.text-red {
  color: var(--adm-color-primary);
}
.text-blue-1 {
  color: var(--adm-color-info-1);
}
.text-blue-2 {
  color: var(--adm-color-info-2) !important;
}
.text-orange {
  color: @orange-1;
}
.text-grey-1 {
  color: @grey-1;
}
.text-grey-2 {
  color: @grey-2;
}
.text-grey-3 {
  color: @grey-3;
}
.text-grey-4 {
  color: @grey-4;
}

.text-w-500 {
  font-weight: 500;
}
.text-w-600 {
  font-weight: 600;
}

.bg-img-center {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
