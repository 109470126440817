.light-table {
  &.size-default {
    .label {
      color: @grey-3;
      font-size: 14px;
      margin-bottom: 0;
    }
    .value {
      color: var(--adm-color-primary);
      font-size: 14px;
    }
  }
  &.size-small {
    .label {
      color: @grey-3;
      font-size: 12px;
      margin-bottom: 0;
    }
    .value {
      color: var(--adm-color-primary);
      font-size: 12px;
    }
  }
}
