.order {
  // position: relative;

  .divider {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .detail {
    font-size: 12px;
    color: @grey-2;
    margin-top: 0.4em;
    p {
      margin-bottom: 0.2em;
    }
  }
}
