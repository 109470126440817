@orange-1: #ff6a1b;

@white: #fff;
@black: #333333;

@grey-0: #eaeaea;
@grey-1: #cacaca;
@grey-2: #aaaaaa;
@grey-3: #606060;
@grey-4: #333333;

@box-horizontal-padding: 18px;

@nav-bar-height: 45px;
@tab-bar-height: 55px;

// 覆蓋 antd 樣式
@primary-color: #e54949;

// 覆蓋 antd-mobile 樣式
:root:root {
  --adm-color-primary: #e54949;
  --adm-color-success: #67bd4e;
  --adm-color-info-1: #3e9cd4;
  --adm-color-info-2: #4574e2;
  --adm-color-info-3: rgb(62 212 191);
  --grey-0: @grey-0;
  --grey-1: @grey-1;
  --grey-2: @grey-2;
  --grey-3: @grey-3;
  --grey-4: @grey-4;
  --body-background: #f8f8f8;
}
