.global-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  padding-bottom: @tab-bar-height;

  &.have-nav-bar {
    padding-top: @nav-bar-height;
  }

  .container {
    min-height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .box-wrap {
    padding-right: @box-horizontal-padding;
    padding-left: @box-horizontal-padding;
  }
}
