.transfer {
  padding-bottom: @box-horizontal-padding;
  .header-content {
    color: @black;
  }

  .icon {
    font-size: 60px;
    color: var(--adm-color-success);
  }
}
