.user-center {
  margin-top: @box-horizontal-padding;
  padding-bottom: @box-horizontal-padding;
  .user-block {
    display: flex;
    align-items: center;
    .username {
      margin-left: 0.8em;
    }
  }

  .tool-section {
    color: @grey-4;
    .title {
      font-size: 12px;
    }
    .label {
      font-size: 14px;
    }
  }
}
