.home {
  position: relative;
  .header {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 @box-horizontal-padding;
    .logo-section {
      display: flex;
      align-items: center;
      .title {
        font-size: 16px;
        color: @black;
        margin-left: 8px;
      }
    }
  }
  .top-menu {
    display: flex;
    padding: 6px 0;
    margin-bottom: 12px;

    .left-block {
      display: flex;
      align-items: center;
      width: 45%;
      .item {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .right-block {
      flex: 1 1 auto;
      padding-left: 0.8em;
    }
    .question {
      width: 14px;
      height: 14px;
      background-color: rgb(17 144 255);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .body-menu {
    .card-title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .title {
        font-size: 14px;
        color: var(--adm-color-primary);
        margin-bottom: 0;
        margin-right: auto;
      }
      .control {
        display: flex;
        align-items: center;
        .label {
          font-size: 14px;
          color: @black;
          margin-right: 4px;
        }
      }
    }
    .item {
      .title {
        font-size: 14px;
        color: @black;
      }
      .sub-title {
        font-size: 12px;
        color: #aaaaaa;
      }
    }
  }
}

.popover-content {
  max-width: 200px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }
}
